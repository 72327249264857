import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { verifyToken } from './http/API'
import MapMacro from './map/MapMacro'
// import AdminSettings from './pages/AdminSettings'
import Auth from './pages/Auth'

function App () {
  const isVerifiedToken = async () => {
    await verifyToken().then(res => {
      if (res.response.success) {
        Cookies.set('first_name', res.response.first_name)
        Cookies.set('last_name', res.response.last_name)
        Cookies.set('email', res.response.email)
      } else {
        Cookies.remove('token')
        window.location.reload()
      }
    })
  }
  const token = Cookies.get('token')
  let userRole
  try {
    userRole = token ? jwt_decode(token).role : undefined
  } catch {
    Cookies.remove('token')
    window.location.reload()
  }
  if (token) {
    try {
      const isTokenExpired = t => Date.now() >= (JSON.parse(atob(t.split('.')[1]))).exp * 1000
      if (isTokenExpired(token)) {
        Cookies.remove('token')
        window.location.reload()
      }
      isVerifiedToken()
      console.log('token check')
    } catch {
      Cookies.remove('token')
      window.location.reload()
    }
  }
  return (
    <>
      <Router>
        <Switch>
          <Route path='/auth' component={Auth} />
          {/* <Route path='/admin' component={userRole === 'ADMIN' ? AdminSettings : Auth} /> */}
          <Route path='/map_elements' component={userRole ? MapMacro : Auth} />
          <Route exact path='*' component={Auth} />
        </Switch>
      </Router>
    </>
  )
}

export default App
