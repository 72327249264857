import { $authHost, $host } from './index'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

export const addContactMessage = async ( props ) => {
  const { data } = await $host.post( 'user/contact', { ...props } )
  return { response: data }
}
export const registration = async ( props ) => {
  const { data } = await $host.post( 'user/registration', { ...props } )
  if ( data.success ) {
    await login( props.email, props.password )
    return { decyph: jwt_decode( data.token ), response: data }
  } else {
    return { response: data }
  }
}

export const login = async ( email, password ) => {
  const { data } = await $host.post( 'user/login', { email, password } )
  console.log( data )
  if ( data.success ) {
    Cookies.set( 'first_name', data.user.first_name )
    Cookies.set( 'last_name', data.user.last_name )
    Cookies.set( 'email', data.user.email )
    Cookies.set( 'token', data.token )
    return { decyph: jwt_decode( data.token ), response: data }
  } else {
    return { response: data }
  }
}

export const getUsers = async () => {
  const { data } = await $authHost.get( 'admin/getusers' )
  return { response: data }
}
export const addGeoJSON = async ( json ) => {
  const { data } = await $authHost.post( 'map/add', { json } )
  return { response: data }
}
export const getGeoJSON = async ( onlyProps = false ) => {
  const { data } = await $authHost.get( `map/get?onlyProps=${ onlyProps }` )
  return { response: data }
}
export const deleteGeoJSON = async ( id ) => {
  const { data } = await $authHost.post( 'map/delete', { id } )
  return { response: data }
}
export const getPlaces = async () => {
  const { data } = await $host.get( 'user/places' )
  return { response: data }
}
export const updateGeoJSON = async ( id, newname, cadaster, soil ) => {
  const { data } = await $authHost.put( 'map/update', { id, newname, cadaster, soil } )
  return { response: data }
}
export const getFieldProps = async ( name ) => {
  const { data } = await $authHost.post( 'fielddata/props', { name } )
  return { response: data }
}
export const getFieldIndexes = async ( props ) => {
  const { data } = await $authHost.post( 'map/indexes', { ...props } )
  return { response: data }
}
export const getElementsMap = async ( props ) => {
  const { data } = await $authHost.post( 'map/element_map', { ...props } )
  return { response: data }
}
export const verifyToken = async () => {
  const { data } = await $authHost.get( 'user/verify' )
  return { response: data }
}
export const getElementData = async () => {
  const { data } = await $authHost.get( 'map/element_data' )
  return { response: data }
}
