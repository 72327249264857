import 'leaflet-geosearch/dist/geosearch.css'
import React, { createContext, Suspense } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { createRoot } from 'react-dom/client'
import App from './App'
import './i18n'
import './styles/index.css'
import 'rsuite/dist/styles/rsuite-default.css'

export const Context = createContext(null)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
<Suspense fallback={(<div>Loading</div>)}>
  <App />
</Suspense>
)
