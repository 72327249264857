import { useState, useEffect, useCallback } from 'react'
import { Alert, AutoComplete, Button, DateRangePicker, Input, Panel, SelectPicker, Slider, Tooltip, Whisper } from 'rsuite'
import { useTranslation } from 'react-i18next'
import { getElementData, getElementsMap } from '../../http/API'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'

function MacroSaved ( { adClass, ukey, update, fieldsList, setImageOnMap } ) {
  const { t } = useTranslation()
  const [ data, setData ] = useState( {} )
  const [ loading, setLoading ] = useState( false )

  const {
    afterToday
  } = DateRangePicker
  const chems = {
    'M_density': 'CO2 Molar Density',
    'M_fraction': 'CO2 Mole Fraction',
    'MX_ratio': 'CO2 Mixing Ratio',
  }

  useEffect( () => {
    const fetch = async () => {
      await getElementData().then( res => {
        if ( res.response.success ) {
          setData( res.response.data.reverse() )
        } else {
          Alert.error( res.response.message )
        }
      } )
    }
    fetch()
  }
    , [ ukey ] )

  function arrayBufferToBase64 ( buffer ) {
    let binary = ''
    const bytes = new Uint8Array( buffer )
    const len = bytes.byteLength
    for ( let i = 0; i < len; i++ ) {
      binary += String.fromCharCode( bytes[ i ] )
    }
    return window.btoa( binary )
  }
  const downloadIndexImage = async ( id, element ) => {
    Alert.warning( 'Cartogram creation will take 1-2 minutes' )
    setLoading( true )
    await getElementsMap( { orID: id, chem: element } ).then( r => {
      setLoading( false )
      if ( r.response.success ) {
        update( ukey + 1 )
        const buffer = arrayBufferToBase64( r.response.image.data )
        const src = 'data:image/png;base64,' + buffer
        download( src, `${ r.response.name }-${ chems[ element ] }-${ r.response.range }.png` )

        const b = r.response.bounds
        const field = r.response.field
        const bounds = [ [ b.yMin, b.xMin ], [ b.yMax, b.xMax ] ]

        setLoading( false )
        setImageOnMap( { src, bounds, json: field.json } )
      } else {
        Alert.error( r.response.message )
      }
    } )
  }
  const download = ( image, name ) => {
    const url = image

    const link = document.createElement( 'a' )
    link.href = url
    link.setAttribute( 'download', name )
    document.body.appendChild( link )
    link.click()
  }
  return (
    <div className={`datapoints-list ${ adClass }`}>
      <Panel>
        <h4>Saved cartograms</h4>
        <div>
          {
            data && data.length > 0 && fieldsList?.length > 0 && data?.map( ( e, key ) => {
              const fld = fieldsList.filter( f => e.field_id === f.id )[ 0 ]
              const fieldName = JSON.parse( fld.json ).properties.name
              return (
                <Panel bordered key={( key + 2 ) * 13}>
                  <p>Field: {fieldName || 'unknown'}</p>
                  <p>Date range: {e.range}</p>
                  <p>Created at: {new Date( e.createdAt ).toLocaleString( 'ru' )}</p>
                  <p>Mean NDVI: {e.NDVI}</p>
                  <p>Mean CO2 Molar Density: {e.M_density}</p>
                  <p>Mean CO2 Mole Fraction: {e.M_fraction}</p>
                  <p>Mean CO2 Mixing Ratio: {e.MX_ratio}</p>
                  <p>CO2 Molar Density Map: {!loading ? <Button color={e.DensityMap ? 'green' : 'yellow'} onClick={() => downloadIndexImage( e.id, 'M_density' )}>{e.DensityMap ? 'Download' : 'Create'}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                  <p>CO2 Mole Fraction Map: {!loading ? <Button color={e.FractionMap ? 'green' : 'yellow'} onClick={() => downloadIndexImage( e.id, 'M_fraction' )}>{e.FractionMap ? 'Download' : 'Create'}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                  <p>CO2 Mixing Ratio: Map: {!loading ? <Button color={e.RatioMap ? 'green' : 'yellow'} onClick={() => downloadIndexImage( e.id, 'MX_ratio' )}>{e.RatioMap ? 'Download' : 'Create'}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                </Panel>
              )
            } )
          }
        </div>
      </Panel>

    </div>
  )
}

export default MacroSaved
