import L from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
// import 'leaflet-distortableimage'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Loader } from 'rsuite'
import { addGeoJSON, getGeoJSON } from '../http/API'
import UploadFilesModal from '../imports/UploadFilesModal'
import CreateField from '../sidecomponents/sidewindows/CreateField'
import MacroElements from '../sidecomponents/sidewindows/MacroElements'
import MacroSaved from '../sidecomponents/sidewindows/MacroSaved'
import UpdateField from '../sidecomponents/sidewindows/UpdateField'
import FieldsListMain from '../sidecomponents/sidewindows/FieldsListMain'
import '../styles/sidenav.css'
import MapControls from './MapControls'

const MapMacro = () => {
  document.documentElement.style.overflow = 'hidden'
  const [ jsonToSave, saveJSON ] = useState( '' )
  const [ heatmap, setType ] = useState( !false )
  const [ MapHeat, setHeatmap ] = useState( null )
  const [ chosenPoints, setChosenPoints ] = useState( [] )
  const [ markerJSON, setMarkerJSON ] = useState( '' )
  const [ jsonToDisplay, setJSON ] = useState( [] )
  const [ datapoints, setPoints ] = useState( [] )
  const [ fieldToUpdate, setFieldToUpdate ] = useState()
  const [ updateKey, update ] = useState( 0 )
  const [ fieldID, setFieldID ] = useState()
  const [ coords, setCoords ] = useState()
  const [ selField, setSelField ] = useState()
  const [ checker, sureness ] = useState( false )
  const [ krigingDone, setKrigingDone ] = useState( false )
  const [ImageOnMap, setImageOnMap] = useState()
  const [ mapType, setMap ] = useState( Cookies.get( 'pref-map' ) || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}' )
  function sortByProperty ( property ) {
    return function ( a, b ) {
      if ( a[ property ] > b[ property ] ) {
        return 1
      } else if ( a[ property ] < b[ property ] ) {
        return -1
      }
      return 0
    }
  }
  const CloseOtherModals = () => {
    setDataPointSelectorShow( false )
    setMacroSaveShow( false )
    setUploadModalShow( false )
    setCreateModalShow( false )
    setUpdateModalShow( false )
    setFieldsListShow( false )
  }
  useEffect( () => {
    const fetch = async () => {
      const fieldsList = await getGeoJSON()
        .catch( ( e ) => {
          if ( String( e ).includes( '401' ) ) {
            // Cookies.remove('token')
            window.location.href = '/map_main'
          }
        } )
      if ( fieldsList.response.success ) {
        setJSON( fieldsList.response.fields.sort( sortByProperty( 'id' ) ) )
        try {
          if ( updateKey === 0 ) {
            fitBounds( JSON.parse( fieldsList.response.fields[ 0 ].json ) )
          }
        } catch ( e ) {
          console.log( e )
        }
      }
      showLayerRef.current.clearLayers()
    }
    fetch()
  }, [ updateKey ] )

  useEffect( () => {
    if ( ImageOnMap ) {
      mapRef.current.fitBounds( ImageOnMap.bounds )
      // searchGeometryRef.current.clearLayers()
      const myStyle = {
        fillOpacity: 0
      }

      // const jsonDisp = L.geoJSON(JSON.parse(ImageOnMap.json), myStyle)
      // jsonDisp.bindTooltip('NDVI = 0.32', { sticky: true })
      // jsonDisp.addEventListener('mouseover', () => (im.openTooltip()))
      // searchGeometryRef.current.addLayer(jsonDisp)
      const im = L.imageOverlay( ImageOnMap.src, ImageOnMap.bounds ).addTo( mapRef.current )
      // im.bindTooltip('NDVI = 0.46', { sticky: true })
      // im.addEventListener('mouseover', () => (im.openTooltip()))
    }
  }, [ ImageOnMap ] )

  useEffect( () => {
    datapoints.forEach( p => {
      const point = L.Marker( p.coordinates )
      point.addTo( mapRef.current )
    } )
  }, [ datapoints ] )
  const addField = async ( field, notClear ) => {
    try {
      let response
      const resp = await addGeoJSON( field ).then( ( res ) => {
        response = res.response
        if ( response.success ) {
          Alert.success( response.message )
          !notClear && clearLayers()
          !notClear && update( updateKey + 1 )
        } else {
          Alert.error( response.message )
        }
      } )
    } catch ( e ) {
      Alert.error( t( 'error.noconnection' ) )
    }
  }
  const [ DataPointSelectorShow, setDataPointSelectorShow ] = useState( true )
  const [ MacroSaveShow, setMacroSaveShow ] = useState( false )
  const [ UploadModalShow, setUploadModalShow ] = useState( false )
  const [ CreateModalShow, setCreateModalShow ] = useState( false )
  const [ updateModalShow, setUpdateModalShow ] = useState( false )
  const [ FieldsListShow, setFieldsListShow ] = useState( false )


  const [ fieldMaster, setfieldMaster ] = useState()
  const [ fieldNameMaster, setfieldNameMaster ] = useState()
  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const [ isLoading, setIsLoading ] = useState( false )

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef( null ) // map
  const paneRef = useRef( null ) // tiles order on top
  const drawnLayerRef = useRef( null ) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef( null ) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef( null ) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef( null ) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef( null ) // search geometries on the map = featureGroup
  // const searchGeometryRef2 = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  function clearLayers () {
    if ( mapRef?.current ) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if ( mapRef.current.hasLayer( drawnLayerRef.current ) ) drawnLayerRef.current.clearLayers()
      if ( mapRef.current.hasLayer( markerRef.current ) ) markerRef.current.clearLayers()
      if ( mapRef.current.hasLayer( tileLayerRef.current ) ) tileLayerRef.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect( () => {
    try {
      map = L.map( 'map', {
        center: [ 37.8752233, 139.0531575 ],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      } )
      L.tileLayer( mapType, {
        subdomains: [ 'mt0', 'mt1', 'mt2', 'mt3' ],
        maxZoom: 18,
        id: 'baselayer'
      } ).addTo( map )
      L.control.attribution( {
        position: 'bottomright'
      } ).addTo( map )
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo( map )
      markerRef.current = L.featureGroup().addTo( map )
      showLayerRef.current = L.featureGroup().addTo( map )
      drawnLayerRef.current = L.featureGroup().addTo( map )
      tileLayerRef.current = L.featureGroup().addTo( map )
      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane( 'leaflet-top-pane', mapRef.current.getPanes().mapPane )
      paneRef.current = topPane

      // Adding Controls
      MapControls( mapRef.current, t )
      mapRef.current.invalidateSize( 9 )
    } catch ( e ) {
      console.log( e )
    }
  }, [] )
  // useEffect(() => {
  //   searchGeometryRef2.current = L.featureGroup().addTo(MapHeat)
  // }, [MapHeat])
  useEffect( () => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix( Cookies.get( 'pref-attr' ) )
    L.tileLayer( mapType, {
      maxZoom: 18,
      id: 'baselayer'
    } ).addTo( mapRef.current )
  }, [ mapType ] )
  useEffect( () => {
    searchGeometryRef.current.clearLayers()
    // searchGeometryRef2.current.clearLayers()
    jsonToDisplay.forEach( ( f ) => {
      const mask = L.geoJSON( JSON.parse( f.json ) )
      const name = JSON.parse( f.json ).properties.name

      if ( JSON.parse( f.json ).geometry?.type === 'Point' ) {
        mask.bindTooltip( () => name, { permanent: true } )
        mask.addEventListener( 'mouseover', () => {
          mask.unbindTooltip()
          const time = new Date( f.createdAt )
          mask.bindTooltip( () => `Создано: ${ time.toLocaleString( 'ru-RU' ) }`, { permanent: true } )
        } )
        mask.addEventListener( 'mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip( () => name, { permanent: true } )
        } )
      } else {
        mask.bindTooltip( () => name )
        mask.addEventListener( 'click', () => { setFieldID( f.id ) } )
        mask.addEventListener( 'dblclick', () => {
          fitBounds( JSON.parse( f.json ) )
        } )
      }
      mask.addEventListener( 'mouseover', () => ( mask.openTooltip() ) )
      searchGeometryRef.current.addLayer( mask )
      // searchGeometryRef2.current.addLayer(mask)
    } )
  }, [ jsonToDisplay ] )
  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback( e => {
    clearLayers()
    const layer = e.layer
    if ( e.layerType === 'marker' ) {
      const gjson = layer.toGeoJSON()

      setCoords( gjson )
      // setMarkerJSON(JSON.stringify(gjson))
      drawnLayerRef.current.addLayer( layer )
    } else {
      const area = L.GeometryUtil.geodesicArea( layer.getLatLngs()[ 0 ] )
      const gjson = layer.toGeoJSON()
      gjson.properties = {
        name: 'test',
        area: area
      }
      saveJSON( JSON.stringify( gjson ) )
      if ( area <= MAX_AREA ) {
        // setSearchLayer(() => layer.toGeoJSON())
      } else {
        Alert.info( t( 'errors.bigarea' ), 5000 )
      }
      drawnLayerRef.current.addLayer( layer )
    }
  }, [] )
  useEffect( () => {
    mapRef.current.on( 'draw:created', handleDrawCreated )
  }, [ handleDrawCreated ] )

  const fitBounds = ( field ) => {
    const mask = L.geoJSON( field )

    if ( field.geometry?.type === 'Point' ) {
      const lat = field.geometry.coordinates[ 1 ]
      const lng = field.geometry.coordinates[ 0 ]
      mapRef.current.panTo( [ lat, lng ], 10 )
    } else {
      mapRef.current.fitBounds( mask.getLayers()[ 0 ].getLatLngs() )
    }
  }

  return (
    <>
      <nav className='navbar-side-new'>
        <button className='new-link-element'>
          <img src='/logo192.png' alt='' width='40px' />
        </button>
        <button className={`new-link-element ${ CreateModalShow ? 'dropdown-chosen' : '' }`} onClick={() => { CloseOtherModals(); setCreateModalShow( !CreateModalShow ) }} title={t( 'map.field.create' )}>
          <FontAwesomeIcon icon={FA.faEdit} />
          <h5>{t( 'map.field.create' )}</h5>
        </button>
        <button className={`new-link-element ${ FieldsListShow ? 'dropdown-chosen' : '' }`} onClick={() => { CloseOtherModals(); setFieldsListShow( !FieldsListShow ) }} title={t( 'map.sidebar.fieldslist' )}>
          <FontAwesomeIcon icon={FA.faList} />
          <h5>{t( 'map.sidebar.fieldslist' )}</h5>
        </button>
        <button className={`new-link-element ${ UploadModalShow ? 'dropdown-chosen' : '' }`} onClick={() => { CloseOtherModals(); setUploadModalShow( !UploadModalShow ) }} title={t( 'map.sidebar.upload' )}>
          <FontAwesomeIcon icon={FA.faFileDownload} />
          <h5>{t( 'map.sidebar.upload' )}</h5>
        </button>
        <button className={`new-link-element ${ DataPointSelectorShow ? 'dropdown-chosen' : '' }`} onClick={() => { CloseOtherModals(); setDataPointSelectorShow( !DataPointSelectorShow ) }}>
          <FontAwesomeIcon icon={FA.faPrescriptionBottle} />
          <h5>CO<sub>2</sub> estimation</h5>
        </button>
        <button className={`new-link-element ${ MacroSaveShow ? 'dropdown-chosen' : '' }`} onClick={() => { CloseOtherModals(); setMacroSaveShow( !MacroSaveShow ) }}>
          <FontAwesomeIcon icon={FA.faDrawPolygon} />
          <h5>Saved cartograms</h5>
        </button>
        {/* <button className='new-link-element' onClick={() => i18n.changeLanguage( i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru' )} title='Смена языка'>
          <FontAwesomeIcon icon={FA.faGlobe} />
          <h5>
            Language: {i18n.language.toUpperCase()}
          </h5>
        </button> */}
        <button onClick={() => { window.location.href = '/profile' }} className='new-link-element' title={t( 'common.return' )}>
          <FontAwesomeIcon icon={FA.faBackward} />
          <h5>{t( 'common.return' )}</h5>
        </button>
      </nav>
      <div className='menu-list'>
        <CreateField updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} fitBounds={fitBounds} sendFunc={addField} fieldJSON={jsonToSave} adClass={!CreateModalShow ? 'hidden' : ''} />
        <UpdateField updatekey={update} upkey={updateKey} fieldJSON={fieldToUpdate} adClass={!updateModalShow ? 'hidden' : ''} onHide={() => { setUpdateModalShow( false ); setFieldsListShow( true ) }} />
        <UploadFilesModal drawLayer={drawnLayerRef.current} saveJSON={saveJSON} searchRef={searchGeometryRef.current} onHide={() => { setUploadModalShow( false ); setCreateModalShow( true ) }} adClass={!UploadModalShow ? 'hidden' : ''} />
      </div>
      <FieldsListMain layer={showLayerRef.current} openEditor={setUpdateModalShow} setFieldToUpdate={setFieldToUpdate} hideFunc={setFieldsListShow} setFieldMaster={setfieldMaster} setFieldNameMaster={setfieldNameMaster} fitBounds={fitBounds} fields={jsonToDisplay} onHide={() => setFieldsListShow( false )} adClass={!FieldsListShow ? 'hidden' : ''} fieldsModal={FieldsListShow} setSelField={setSelField} />
      <div className='navbar-side-wrapper'>
        <MacroElements fieldID={fieldID} onHide={() => setDataPointSelectorShow( false )} adClass={!DataPointSelectorShow ? 'hidden' : ''} coordinates={coords} layerRef={markerRef} mapRef={mapRef} fieldsList={jsonToDisplay} setFieldID={setFieldID} setDispPoints={setChosenPoints} setHeatmap={setType} heatmap={heatmap} setKrigingDone={setKrigingDone} ukey={updateKey} update={update} goto={() => { CloseOtherModals(); setMacroSaveShow( true ) }} />
        <MacroSaved fieldID={fieldID} onHide={() => setMacroSaveShow( false )} adClass={!MacroSaveShow ? 'hidden' : ''} ukey={updateKey} update={update} fieldsList={jsonToDisplay} setImageOnMap={setImageOnMap}/>
      </div>
      <div id='map' className={!heatmap ? 'hidden-map' : 'vis-map'}>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapMacro
