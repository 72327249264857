import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, FlexboxGrid, Icon, Input, InputGroup, Tooltip, Whisper } from 'rsuite'
import { login, registration } from '../http/API'
import '../styles/auth.css'

const Auth = () => {
  const { t } = useTranslation()
  const [authType, setType] = useState('login')
  const [datareg, setDataReg] = useState({  })
  const [datalogin, setDatalogin] = useState({})
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)
  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }
  const onSubmitLogin = async () => {
    const tempErrors = validate(datalogin)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      setLoading(true)
      try {
        await login(datalogin.email, datalogin.password).then((res) => {
          setLoading(false)
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            setTimeout(2000)
            window.location.href = '/map_elements'
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(t('error.noconnection'))
      }
    } else {
      Alert.error(t('error.checkdata'))
    }
  }
  const onSubmitReg = async () => {
    const tempErrors = validate(datareg)
    if (Object.keys(tempErrors).length === 0) {
      try {
        await registration(datareg).then((res) => {
          setLoading(false)
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            window.location.href = '/map_elements'
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(t('error.connectionerror'))
      }
    }
  }

  const validate = (data) => {
    const errors = {}
    if (!data.email) {
      errors.email = t('Errors.empty_email')
    }
    const emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
    if (data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('Errors.valid_email')
    }
    if (!data.password) {
      errors.password = t('Errors.empty_password')
    }
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'i')
    if (!passwordPattern.test(data.password)) {
      errors.password = t('Errors.valid_password')
    }
    return errors
  }

  return (
    <div className='auth-wrapper-parent'>
      <div className='auth-wrapper'>
        <div className='auth-modal'>
          <h3>{t('auth.title')}</h3>
          <br />
          <div className='auth-strip'>
            <button onClick={() => setType('login')} style={authType === 'login' ? { backgroundColor: 'white' } : {}}>{t('welcome.signin')}</button>
            <button onClick={() => setType('reg')} style={authType === 'reg' ? { backgroundColor: 'white' } : {}}>{t('welcome.signup')}</button>
          </div>
          <div className='auth-login' hidden={authType !== 'login'}>
            <h5>{t('common.email')}</h5>
            <Input
              placeholder={t('common.email')} type='email' id={datalogin.email} onChange={e => setDatalogin({
                ...datalogin,
                email: e
              })} size='md'
            />
            <h5>{t('common.password')}</h5>
            <InputGroup>
              <Input
                placeholder={t('common.password')} size='md'
                id={datalogin.password}
                type={passwordType}
                onChange={e => setDatalogin({
                  ...datalogin,
                  password: e
                })}
              />
              <InputGroup.Addon>
                <Icon icon={eye} onClick={togglePassword} />
              </InputGroup.Addon>
            </InputGroup>
            <br />
            <div className='auth-flex'>
              {/* <Button
            appearance='primary' size='sm' id='changetype' onClick={() => setType('')} className='m-r-15  inc'
          >
            Назад
          </Button> */}
              <Button
                appearance='primary' size='xxl' onClick={onSubmitLogin} loading={loading}
              >
                {t('login.button')}
              </Button>
            </div>
          </div>
          <div className='auth-reg' hidden={authType !== 'reg'}>
            <FlexboxGrid>
              <h5>{t('common.name')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.name')} onChange={e => setDataReg({
                    ...datareg,
                    first_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <h5>{t('common.surname')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.surname')} onChange={e => setDataReg({
                    ...datareg,
                    last_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <h5>{t('common.email_dif')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.email_dif')} csize='md' type='email' onChange={e => setDataReg({
                    ...datareg,
                    email: e
                  })}
                />
              </FlexboxGrid.Item>

              <h5>{t('common.password')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='focus' speaker={
                    <Tooltip>{t('signup.passwordtooltip')} </Tooltip>
                }
                >
                  <InputGroup>
                    <Input
                      placeholder={t('common.password')} size='md'
                      type={passwordType}
                      onChange={e => setDataReg({
                        ...datareg,
                        password: e
                      })}
                    />
                    <InputGroup.Addon>
                      <Icon icon={eye} onClick={togglePassword} />
                    </InputGroup.Addon>
                  </InputGroup>
                </Whisper>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className='auth-flex'>
              <Button
                appearance='primary' size='sm' onClick={onSubmitReg} loading={loading} className='m-r-15  inc'
              >
                {t('welcome.signup')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Auth
